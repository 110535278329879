<template>
  <div>
    <div class="row no-gutters align-items-stretch">
      <div class="col-lg-4 col-xl-3 order-2 order-lg-1 align-items-stretch">
        <section class="h-100">
          <app-contacts-filter
            :searchImput="search"
            :tipologiaAttivita="typeActivity"
            :attivita="activity"
            :listFilter="this.filter"
            :courses="this.courses"
            :complexFilterMethod="editfilter"
            :startFilterMethod="callSearch"
            :removeComplexFilterMethod="removeFilter"
            :removeAllComplexFilterMethod="removeAllFilter"
          ></app-contacts-filter>
        </section>
      </div>

      <div class="col-12 col-lg-8 col-xl-9 order-1 order-lg-2">
        <b-skeleton-wrapper class="row no-gutters" :loading="loading">
          <div class="col-12 pl-2 pr-2">
            <b-skeleton
              width="25%"
              animation="wave"
              class="mt-4 mb-4"
            ></b-skeleton>

            <b-skeleton-table
              :rows="20"
              :columns="9"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </div>
        </b-skeleton-wrapper>
        <div class="row no-gutters" v-if="loading == true">
          <app-contacts-actions
            :getRow="this.contact"
            :listTypeActivity="typeActivity"
            :listActivity="activity"
            :downloadMethod="exportResult"
            :nContact="this.totalRows"
            :listCourses="this.coursesNewContact"
            @selectRow="sendSelectRow"
            @updateList="listContact"
          >
          </app-contacts-actions>
        </div>
       
        <div class="row no-gutters">
          <app-contacts-table
            :getItems="this.contact"
            :getRow="this.totalRows"
            :selectRow="selectAllRow"
            :selectPerPage="this.selectPerPage"
            @changePage="listContact"
            v-if="loading == true"
          >
          </app-contacts-table>
       
        </div>
            <div class="d-flex mt-0 flex-row-reverse">
          <div class="col-2 mb-2">
            <b-form-select v-model="selectPerPage" @change="listContact(0)" :options="optionsPerPage" size="sm" ></b-form-select>
          </div>
          <div class="mr-2 mb-2 mt-1">
            <label><small>Numero risultati</small></label>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiAxios from "../libs/apiAxios";
import AppContactsActions from "../components/AppContactsActions.vue";
import AppContactsTable from "../components/AppContactsTable.vue";
import { mapActions } from "vuex";
import AppContactsFilter from "../components/AppContactsFilter.vue";
import { getCoursesList } from '../helpers';

export default {
  components: { AppContactsTable, AppContactsActions, AppContactsFilter },
  /* components: { AppContactsActions, AppContactsFilter }, */
  name: "Contatti",

  data() {
    return {
      contact: [],
      activity: [],
      courses: [],
      coursesNewContact: [],
      loading: false,
      typeActivity: [],
      totalRows: 0,
      selectAllRow: false,
      filter: [],
      selectPerPage: 20,

      /*  search: "", 162*/
      search: {
        searchFilter: "",
        courseFilter: "",
        isNewsletter: "",
      },

      newContactCourse: {},
  
      optionsPerPage: [
          { value: 20, text: '20' },
          { value: 50, text: '50' },
          { value: 100, text: '100' },
          { value: 200, text: '200' },
          { value: 300, text: '300' },
        ],

      itemSelection: false,
      currentPage: 0,

    };
  },

  created() {
    this.listTipeActivity();
    this.listCourses();
    this.listActivity();
    this.listContact(0);
    this.nrItem();
    this.syncFilter();
  },

  methods: {
    ...mapActions([
      "contact/getContactsPage",
      "contact/archiveSingleContacts",
      "courses/getCourses",
    ]),

    syncFilter() {
      if (localStorage.getItem("filter")) {
        this.filter = JSON.parse(localStorage.getItem("filter"));
      }
    },

    async listCourses() {
      try {
        await this.$store.dispatch("courses/getCourses").then(() => {
          //this.courses = this.$store.getters["courses/listCourses"];
          this.newContactCourse = this.$store.getters["courses/listCourses"];

          const courses = this.$store.getters["courses/listCourses"];

          if (process.env.VUE_APP_API_ENDPOINT != "https://apicrmits.bizonweb.it/") {
             const unassigned = {
                code : "UNASSIGNED",
                description : "",
                id : -3,
                value : -3,
                name : "Senza corso",
                sorting : 0
              }
              const biennial = {
                code : "BIENNIAL",
                description : "",
                id : -1,
                value : -1,
                name : "Biennio",
                sorting : 1
              }
              const triennial = {
                code : "TRIENNIAL",
                description : "",
                id : -2,
                value : -2,
                name : "Triennio",
                sorting : 2
              }

              this.courses.unshift(
                {
                  label: 'Percorsi di studio',
                  options: [ unassigned,biennial,triennial ],
                  sorting : 1
                }
              );

              courses.forEach((element) => {
                element.value = element.id;
              });

              this.courses.push(
                {
                  label: 'Corsi',
                  options: courses,
                  sorting : 1
                }
              );
          }


          if(process.env.VUE_APP_API_ENDPOINT == "https://apicrmits.bizonweb.it/"){
            this.courses = getCoursesList(courses, true);
            this.coursesNewContact = getCoursesList(courses, false);

            // const unassigned = {
            //     code : "UNASSIGNED",
            //     description : "",
            //     id : -3,
            //     value : -3,
            //     name : "Senza corso",
            //     sorting : 0
            //   }
            // const biennial = {
            //     code : "ITS",
            //     description : "",
            //     id : -1,
            //     value : -1,
            //     name : "ITS",
            //     sorting : 1
            //   }
            // const triennial = {
            //     code : "IFTS",
            //     description : "",
            //     id : -2,
            //     value : -2,
            //     name : "IFTS",
            //     sorting : 2
            //   } 


            //   this.courses.unshift(
            //     {
            //       label: 'Percorsi di studio',
            //       options: [ unassigned,biennial,triennial ],
            //       sorting : 1
            //     }
            //   );
              
            //   this.courses.push(
            //     {
            //       label: 'Corsi',
            //       /* options: courses, */
            //       options: [],
            //       sorting : 1
            //     }
            //   ); 


            //   const typeDipartement = [];
            //   const dipartementModa = [];
            //   const dipartemenMarketing = [];
            //   const dipartemenDesign = [];
            //   const coursesIFTS = [];
            //   courses.forEach((element) => {
      
            //     const getType = element.type;
             
            //     const splitType = getType.split(';');

            //     if(splitType.length > 1){
               
            //       element.html = `&ensp; &ensp; &ensp; &ensp;${element.name}`;
            //      /*  element.value = element.id; */
            //       if(splitType.includes('MODA')){
            //         element.sorting = 151;
            //         element.value = `${element.id}`;
            //         dipartementModa.push(element.id);
            //       }

            //       if(splitType.includes('MARKETING')){
            //         element.sorting = 161;
            //         element.value = `${element.id}`;
            //         dipartemenMarketing.push(element.id);
            //       }

            //       if(splitType.includes('DESIGN')){
            //         element.sorting = 171;
            //         element.value = `${element.id}`;
            //         dipartemenDesign.push(element.id);
            //       }

            //       this.courses.push(element);
            //     }
            //     if(splitType.length == 1){
                  
            //       element.html = `&ensp; &ensp;${element.name}`;
            //       /* element.value = element.id; */
                  
            //       if(splitType.includes('DIP_MODA')){
                    
            //         element.value = `${element.id},${dipartementModa.toString()}`;
            //       }
            //       if(splitType.includes('DIP_MARKETING')){
                    
            //         element.value = `${element.id},${dipartemenMarketing.toString()}`;
            //       }
            //       if(splitType.includes('DIP_DESIGN')){
                    
            //         element.value = `${element.id},${dipartemenDesign.toString()}`;
            //       }

            //       typeDipartement.push(element);
            //     }

            //   });

            //   let uniqueTypeDipartement = [
            //       ...new Map(typeDipartement.map((item) => [item["code"], item])).values(),
            //   ];  

            //   uniqueTypeDipartement.forEach((element) => {
            //     if(element.type!='IFTS') {
            //       this.courses.push(element);
            //     } else {
            //       element.html = `${element.name}`;
            //       element.value = element.id;
            //       coursesIFTS.push(element);
            //     }
            //   });

            //   console.log("uniqueTypeDipartement", uniqueTypeDipartement);
            //   console.log("courses", this.courses);
            //   this.courses = this.courses.sort((a,b) => a.sorting - b.sorting);
             
            //   this.courses.push(
            //     {
            //       label: 'IFTS ',
            //       options: coursesIFTS,
            //       sorting : 300
            //     }
            //   ); 
          }

          console.log('this.courses', this.courses);

          return this.listCourseUser();
        });
      } catch (e) {
        console.log('tendina');
        console.log(this.courses[1]);
        console.log({ message: "Some error getCourses--" });
      }
    },

    async listActivity() {
      try {
        await this.$store.dispatch("activity/getActivity").then(() => {
          this.activity = this.$store.getters["activity/activity"];
          
          return this.activity;
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async listTipeActivity() {
      try {
        await this.$store.dispatch("typeActivity/getTypeActivity").then(() => {
          this.typeActivity = this.$store.getters["typeActivity/listActivity"];

          return this.typeActivity;
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async listContact($currentPage) {
      /* this.loading = false; */
    /*   console.log(this.search);
      console.log($currentPage); 
      console.log(this.selectPerPage); */
      /* console.log("listContact ### localstorage"); */


      if (localStorage.getItem("searchText")) {
        this.search.searchFilter = localStorage.getItem("searchText");
      }
      if (localStorage.getItem("courseName")) {
        this.search.courseFilter = localStorage.getItem("courseName");
      }

      if (localStorage.getItem("isNewsletter")) {
        this.search.isNewsletter = localStorage.getItem("isNewsletter");
      }

      if (localStorage.getItem("filter")) {
        console.log(localStorage.getItem("filter"));
        console.log('entrato 2')
        this.filter = JSON.parse(localStorage.getItem("filter"));
      }


      console.log(this.search.courseFilter);
      console.log($currentPage);
      try {
        await this.$store
          .dispatch("contact/getContactsPage", {
            nrCurrentPage: $currentPage,
            perPage: this.selectPerPage,
            searchText: this.search.searchFilter,
            courseName: this.search.courseFilter,
            isNewsletter: this.search.isNewsletter,
            filter: this.filter,
          })
          .then(() => {
            this.perPage = this.selectPerPage;
            this.contact = this.$store.getters["contact/contact"];
            this.selection = this.contact;
            this.loading = true;
            
            
            this.nrItem();
            console.log(this.contact);
            return this.contact;
          });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error getContactsPage" });
      }
    },

    async nrItem() {
      this.totalRows = this.$store.getters["contact/nrItem"];
      return this.totalRows;
    },

    async exportResult() {
      const $filter = this.filter;
      let $searchText = null;
      if (this.search.searchText == "") {
        $searchText = null;
      } else {
        $searchText = this.search.searchFilter;
      }
    

      let $courseName = null;
      if (this.search.courseFilter === "") {
        $courseName = null;
      } else {
        $courseName = this.search.courseFilter;
      }

      let $typeCourse = null;
      if(process.env.VUE_APP_API_ENDPOINT == "https://apicrmits.bizonweb.it/"){
        if ($courseName == -1) {
          $typeCourse = "ITS";
        } else if ($courseName == -2) {
          $typeCourse = "IFTS";
        }  else if ($courseName == -3) {
          $typeCourse = "UNASSIGNED";
        }
      } else {
        if ($courseName == -1) {
          $typeCourse = "BIENNIAL";
        } else if ($courseName == -2) {
          $typeCourse = "TRIENNIAL";
        }  else if ($courseName == -3) {
          $typeCourse = "UNASSIGNED";
        }
      }
    

      let $isNewsletter = null;
      if (this.search.isNewsletter === "") {
        $isNewsletter = null;
      } else {
        $isNewsletter = this.search.isNewsletter;
      }
      
      

      let complexFilters = "";
      $filter.forEach(function(item) {
      let strig = "";

      let $editStartEventDt = null;
      if (item.startEventDt !== null) {
        $editStartEventDt = item.startEventDt;
      } else {
        $editStartEventDt = null;
      }

      let $editEndEventDt = null;
      if (item.endEventDt !== null) {
        $editEndEventDt = item.endEventDt;
      } else {
        $editEndEventDt = null;
      }


      if($editStartEventDt != null && $editEndEventDt === null){
        $editEndEventDt = $editStartEventDt;
      }

      if($editStartEventDt === null && $editEndEventDt != null){
        $editStartEventDt = $editEndEventDt;
      }

      if (item.eventDt === null && item.note === null) {
        console.log('### SCELTA 1');
        strig = `${complexFilters}&complexFilters[]=${item.idActivityType};${item.idActivity};${item.condition};${item.bondType};;;`;
      } else if (item.eventDt !== null && item.note === null) {
        console.log('### SCELTA 2');
        strig = `${complexFilters}&complexFilters[]=${item.idActivityType};${item.idActivity};${item.condition};${item.bondType};${$editStartEventDt};${$editEndEventDt};`;
      } else if (item.eventDt === null && item.note !== null) {
        console.log('### SCELTA 3');
        strig = `${complexFilters}&complexFilters[]=${item.idActivityType};${item.idActivity};${item.condition};${item.bondType};;;${item.note}`;
      } else {
        console.log('### SCELTA 7');
        strig = `${complexFilters}&complexFilters[]=${item.idActivityType};${item.idActivity};${item.condition};${item.bondType};${$editStartEventDt};${$editEndEventDt};${item.note}`;
      }
      complexFilters = strig;
      });
       if ($isNewsletter !== null) {
         if (
          $searchText !== null &&
          $courseName !== null &&
          complexFilters !== ""
        ) {
        
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&isNewsletter=${$isNewsletter}&idCourses=${$courseName}${$string_typeCourse}${complexFilters}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 1 file");
        } else if (
          $searchText !== null &&
          $courseName !== null &&
          complexFilters === ""
        ) {
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }  
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&isNewsletter=${$isNewsletter}&idCourses=${$courseName}${$string_typeCourse}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 2 file");
        } else if (
          $searchText === null &&
          $courseName !== null &&
          complexFilters === ""
        ) {

        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&idCourses=${$courseName}&isNewsletter=${$isNewsletter}${$string_typeCourse}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 3 file");
        } else if (
          $searchText !== null &&
          $courseName === null &&
          complexFilters === ""
        ) {


        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&isNewsletter=${$isNewsletter}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 4 file");
        } else if (
          $searchText === null &&
          $courseName !== null &&
          complexFilters !== ""
        ) {
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&idCourses=${$courseName}${$string_typeCourse}&isNewsletter=${$isNewsletter}${complexFilters}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          });
        console.log("### CALL 5 file");
        } else if (
          $searchText !== null &&
          $courseName === null &&
          complexFilters !== ""
        ) {
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&isNewsletter=${$isNewsletter}${complexFilters}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          });
        console.log("### CALL 6 file");
        } else if (
          $searchText === null &&
          $courseName === null &&
          complexFilters !== ""
        ) {
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&isNewsletter=${$isNewsletter}${complexFilters}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 7 file");
        } else {
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&isNewsletter=${$isNewsletter}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 8 file");
        }
       } else {
        if (
          $searchText !== null &&
          $courseName !== null &&
          complexFilters !== ""
        ) {
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }  
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&idCourses=${$courseName}${$string_typeCourse}${complexFilters}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 1 file");
        } else if (
          $searchText !== null &&
          $courseName !== null &&
          complexFilters === ""
        ) {
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&idCourses=${$courseName}${$string_typeCourse}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 2 file");
        } else if (
          $searchText === null &&
          $courseName !== null &&
          complexFilters === ""
        ) {
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&idCourses=${$courseName}${$string_typeCourse}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 3 file");
        } else if (
          $searchText !== null &&
          $courseName === null &&
          complexFilters === ""
        ) {


        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 4 file");
        } else if (
          $searchText === null &&
          $courseName !== null &&
          complexFilters !== ""
        ) {
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&idCourses=${$courseName}${$string_typeCourse}${complexFilters}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          });
        console.log("### CALL 5 file");
        } else if (
          $searchText !== null &&
          $courseName === null &&
          complexFilters !== ""
        ) {
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}${complexFilters}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          });
        console.log("### CALL 6 file");
        } else if (
          $searchText === null &&
          $courseName === null &&
          complexFilters !== ""
        ) {
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true${complexFilters}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 7 file");
        } else {
        await apiAxios
          .get(
            `api/foppacrm/Contacts/spreadsheet?joinWiths[]=all&sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_contact.xlsx");
            document.body.appendChild(fileLink);

            this.toast(
              `Esporta Contatti`,
              `Contatti Esportati corettamente`,
              "success",
              true
            );
            fileLink.click();
          })
          .catch((error) => console.log(error));
        console.log("### CALL 8 file");
        }
       }
      
      

     
    },

    toast(title, message, variant, append = false) {
      this.counter++;
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: append,
      });
    },

    editfilter(data) {
      this.filter.push(data);
      return this.filter;
    },

    callSearch(data) {
      this.search = { ...data };
      console.log(this.search);
      this.listContact(0);
    },

    removeFilter(data) {
      console.log(data);
      console.log(this.filter);
      this.filter.splice(data, 1);
      localStorage.setItem("filter", JSON.stringify(this.filter));
      console.log(this.filter);
    },

     removeAllFilter() {
     /*  console.log(data); */
      console.log(this.filter); 

  
        this.search.searchFilter = "";
        localStorage.removeItem("searchText");
    
  
        this.search.courseFilter = 0;
        localStorage.removeItem("courseName");
      

    
        this.filter = [];
        localStorage.removeItem("filter");
         this.$router.go();
        console.log(this.filter); 
    },

    sendSelectRow(data) {
      if (data === 1) {
        console.log("sendSelectRow true");
        this.selectAllRow = true;
      } else {
        console.log("sendSelectRow false");
        this.selectAllRow = false;
      }
    },
  },
};
</script>

<template>
  <div class="col-12 pl-2 pr-2 h-100">

    <b-table
      :items="getItems"
      :fields="fields"
      :per-page="selectPerPage"
      stacked="md"
      show-empty
      small
      striped
      
      id="AppContactTable"
      thead-tr-class="m-table"
      
      ref="contactTable"
      
    >
    <b-tr class="d-md-none">
    </b-tr>
      <template #cell(selection)="row" :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}">
        
        <span v-if="row.item.status === 'ENABLED'">
          <input
            type="checkbox"
            v-model="row.item.selection"
            :checked="selectRow"
          />
        </span>
        <span v-else>
          <input type="checkbox" v-model="row.item.selection" disabled />
        </span>
      </template>
      <template #cell(activity)="row" >
        <!--  {{ row.item.contactsActivities }} -->
        <div class="d-flex" >
          <div class="d-flex" v-if="row.item.contactsActivities.length > 0">
            <span :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
              v-for="(code, index) in orderActivitiesContacts(
                row.item.contactsActivities
              )"
              :key="code.id"
            >
              <div v-if="index <= 2" >
                <!-- {{ code.idActivity }} ; -->
                <!-- {{ activityTypeCodeById(code.idActivity) }} -->
                <b-badge class="m-badge ml-1" :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
                  ><span>
                    {{ activityTypeCodeById(code.idActivity) }}
                  </span></b-badge
                >
              </div>
            </span>
          </div>
          <div
            class="d-flex ml-1"
            v-if="row.item.contactsActivities.length > 3"
          >
            <b-badge class="m-badge">
              <span>+{{ row.item.contactsActivities.length - 3 }}</span>
            </b-badge>
          </div>
        </div>
      </template>
      <template #cell(courses)="row">
        <div class="d-flex">
            
          <div class="d-flex" v-if="row.item.contactsCourses.length > 0">
             <!--  {{row.item.status}} -->
            <span
              v-for="(code, index) in row.item.contactsCourses"
              :key="code.id"
            >
              <div v-if="index <= 0">
                <small>
                  {{ typeCourseById(code.idCourse) }}
                </small>
              </div>
            </span>
          </div>
        </div>
      </template>
      <template #cell(lastActivity)="row">
        <span v-if="row.item.contactsActivities.length > 0" >
          {{ LastActivityNameById(row.item.contactsActivities) }}
          
        </span>
        <span  v-if="row.item.notes != ''" >
          <hr class="mt-0 mb-0"> 
          <small><strong v-html="row.item.notes" ></strong></small>
        </span>
      </template>
      <template #cell(Nome)="row">
        {{ row.item.person.firstName }} {{ row.item.person.lastName }}
      </template>

      <template #cell(privacy)="row" :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}">
        <div class="d-flex justify-content-center">
          <span v-if="row.item.isPrivacy == true">
            <b-icon
              :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
              icon="check-square"
              scale="2"
              font-scale="0.7"
              variant="success"
            ></b-icon>
          </span>
          <span v-else>
            <b-icon
              :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
              icon="x-square"
              scale="2"
              font-scale="0.7"
              variant="danger"
            ></b-icon>
          </span>
        </div>
      </template>

      <template #cell(newsletter)="row">
        <div class="d-flex justify-content-center">
       
          <span v-if="row.item.isNewsletter == true">
            <b-icon
              :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
              icon="check-square"
              scale="2"
              font-scale="0.7"
              variant="success"
            ></b-icon>
          </span>
          <span v-else>
            <b-icon
              :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
              icon="x-square"
              scale="2"
              font-scale="0.7"
              variant="danger"
            ></b-icon>
          </span>
        </div>
      </template>

      <template #cell(actions)="row">
        <span v-if="row.item.status === 'ENABLED'">
          <b-button
            size="sm"
            :to="{
              name: 'DettagliContatto',
              query: { id: row.item.id },
            }"
            class="mr-1"
          >
            Dettagli
          </b-button>
          <!--     <b-button
            size="sm"
            variant="outline-primary"
            @click="archiveRow(row.item.id)"
          >
            Archivia
          </b-button> -->
        </span>
        <span v-else>
          Archiviato
        </span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="getRow"
      size="sm"
      :per-page="selectPerPage"
      aria-controls="AppContactTable"
      align="center"
     
    ></b-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["getItems", "getRow", "changePage", "refresh", "selectRow","selectPerPage"],
  name: "AppContactsTable",
  data() {
    return {
      fields: [
        {
          key: "selection",
          label: "",
          sortable: false,
          thClass: "",
          tdClass: "d-flex justify-content-start ",
        },
        {
          key: "Nome",
          label: "Nome Cognome",
          sortable: false,
        },
        {
          key: "person.email",
          label: "Email",
          sortable: false,
        },
        {
          key: "person.phoneNumber",
          label: "Numero",
          sortable: false,
        },
        {
          key: "courses",
          label: "Corso interesse",
          sortable: false,
        },
        {
          key: "activity",
          label: "Tipologia Attività",
          sortable: false,
        },
        {
          key: "lastActivity",
          label: "Ultima Attività | Nota",
          sortable: false,
        },
        {
          key: "privacy",
          label: "Privacy",
          sortable: false,
        },
        {
          key: "newsletter",
          label: "Newsletter",
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
          thClass: "",
          tdClass: "d-flex justify-content-end ",
        },
      ],

      //Inizializazione tabella
      sortBy: "Ord",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,

    };
  },
  created() {
    /* this.activityNameById(70);
    this.activityTypeCodeById(70); */
    
  },

  methods: {
    ...mapActions(["contact/getContactsPage", "contact/archiveSingleContacts"]),

    contactTableRefresh() {
     /*  console.log("contactTable"); */
      this.$refs.contactTable.refresh();
    },

    isIts() {
      if(process.env.VUE_APP_API_ENDPOINT == "https://apicrmits.bizonweb.it/"){
        return true;
      } else {
        return false;
      }
    },

  /*   getCurrentPage(){
      this.currentPage = this.numberPageThis;
    }, */

    numberPerPage() {
      /* console.log(this.$store.getters["contact/perPage"]); */
      /* this.perPage = this.$store.getters["contact/perPage"];
      console.log(this.$store.getters["contact/perPage"]); */
      return this.perPage;
    },

    typeCourseById($id) {
      const courseName = this.$store.getters["courses/typeCourseById"]($id);

      let name = 0;
      if (courseName !== undefined) {
        name = courseName.name;
      } else {
        name = 0;
      }
      return name;
      /* return courseName; */
    },

    /*  activityNameById($id) {
      const activityName = this.$store.getters["activity/activityById"]($id)
        .activity.name;

      return activityName;
    }, */

    activityNameById($id) {
      const activityName = this.$store.getters["activity/activityById"]($id);

      let name = 0;
      if (activityName !== undefined) {
        name = activityName.activity.name;
      } else {
        name = 0;
      }
      return name;
    },

    LastActivityNameById($array) {
      let activitiesArray = $array;
     /*  console.log(activitiesArray); */
      const data = [];
      activitiesArray.forEach((element) => {
        data.push({
          idActivity: element.idActivity,
          eventDt: new Date(element.eventDt).getTime(),
          note: element.note,
        });
      });

      function compare(a, b) {
        if (
          a == undefined ||
          b == undefined ||
          a.eventDt == undefined ||
          b.eventDt == undefined
        ) {
          return 0;
        }
        if (new Date(b.eventDt).getTime() < new Date(a.eventDt).getTime()) {
          return -1;
        }
        if (new Date(a.eventDt).getTime() > new Date(b.eventDt).getTime()) {
          return 1;
        }
        return 0;
      }

      const sortActivities = data.sort(compare);

      const activitiesId = sortActivities[0].idActivity;
      /* const activitiesNote = sortActivities[0].note; */
      const activityName = this.$store.getters["activity/activityById"](
        activitiesId
      );

      let name = '';
      if (activityName !== undefined) {
        /* console.log(activityName.activity); */
        /* if(process.env.VUE_APP_API_ENDPOINT == "https://apicrmits.bizonweb.it/"){
      
          if (activitiesNote !== '') {
            name = `${activityName.activity.name} <hr class="mt-0 mb-0"> 
            
            <small><strong>${activitiesNote}<strong></small>`;
          } else {
            name = activityName.activity.name;
          }
        } else { */
          name = activityName.activity.name;
        /* } */
      } else {
        name = 0;
      }
      return name;
    },

    orderActivitiesContacts($array) {
      /* const sortedActivities = $array.sort((a, b) => b.eventDt - a.eventDt); */
      /*   const sortedActivities = $array.sort((a, b) =>
        a.eventDt > b.eventDt ? 1 : -1
      ); */
      let activitiesArray = $array;
      const data = [];
      activitiesArray.forEach((element) => {
        data.push({
          idActivity: element.idActivity,
          eventDt: new Date(element.eventDt).getTime(),
        });
      });

      function compare(a, b) {
        if (
          a == undefined ||
          b == undefined ||
          a.eventDt == undefined ||
          b.eventDt == undefined
        ) {
          return 0;
        }
        if (new Date(b.eventDt).getTime() < new Date(a.eventDt).getTime()) {
          return -1;
        }
        if (new Date(a.eventDt).getTime() > new Date(b.eventDt).getTime()) {
          return 1;
        }
        return 0;
      }

      const sortedActivities = data.sort(compare);

      /*   const sortedActivities = $array; */
      /*    console.log("orderActivitiesContacts"); */
      /*  const activity = this.$store.getters["contact/activitiesContactsById"](
        $id
      ); */

      return sortedActivities;
    },

    activitiesContactsById($id) {
      const activity = this.$store.getters["contact/activitiesContactsById"](
        $id
      );

      return activity;
    },

    activityTypeCodeById($id) {
      const activityType_Code = this.$store.getters["activity/activityById"](
        $id
      );
      /* const activity = this.$store.getters["activity/activity"]; */
      /*  console.log(activity); */
      let code = 0;
     /*  console.log(activityType_Code); */
      if (
        activityType_Code !== undefined &&
        activityType_Code.activity !== undefined
      ) {
        code = activityType_Code.activity.activityType_Code;
      } else {
        code = 0;
      }
      return code;

      /* return activityType_Code; */
    },

    async archiveRow($id) {
     /*  console.log($id); */
      try {
        await this.$store
          .dispatch("contact/archiveSingleContacts", {
            idContact: $id,
          })
          .then(() => {
            this.contact = this.$store.getters["contact/contact"];

            return this.contact;
          });
      } catch (e) {
        console.log({ message: "Some error archiveSingleContacts" });
      }
    },

     setTo($page) {
      console.log($page -1); 
      console.log(this.currentPage);
      this.$emit("changePage",$page -1); 
    }

  },

  watch: {
    currentPage(newPage) {
      console.log('Change to page', newPage)
      this.setTo(newPage);
      
      
    }, 
  },
};
</script>
